<template>
  <div>
    <v-card>
      <v-card-title>Vincular usuário</v-card-title>

      <v-card-text>
        <v-row class="mt-3">
          <v-col>
            <UsersToLinkAutocomplete
              label="Buscar Aluno/Professor"
              v-model="formPatient"
              :institutionId="institutionId"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              small
              class="primary darken-1 mr-2"
              @click="linkUserToInstitution()"
              :loading="loading"
            >
              <v-icon left>mdi-content-save</v-icon> Vincular
            </v-btn>
            <v-btn
              outlined
              small
              text
              @click="closeOrCancel()"
              :loading="loading"
            >
              <v-icon left>mdi-close</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Operação realizada com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeOrCancel()">Ok</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import UsersToLinkAutocomplete from "@/components/Users/UsersToLinkAutocomplete.vue";

export default {
  components: {
    UsersToLinkAutocomplete,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    institutionId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      formPatient: null,
      loading: false,
      successDialog: false,
    };
  },
  methods: {
    async linkUserToInstitution() {
      try {
        this.loading = true;

        let url = `admin/instituicoes/${this.institutionId}/usuarios/vincular`;

        await this.$axios.post(url, this.formPatient);

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    closeOrCancel() {
      if (this.modal) this.$emit("close-dialog");
      else this.$router.go(-1);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
};
</script>

<style></style>
